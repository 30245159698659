import * as React from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import PropTypes from 'prop-types'

import Layout from '../components/layout'

import Image from '../components/partials/image'

const ArticlePage = ({ data }) => {
	const article = data.strapi.article

	return (
		<Layout page={data.strapi.blog} metadata={article.metadata}>
			<div className="container pt-80 sm:pt-120 lg:pt-0">
				<div className="lg:w-10/12 mx-auto">
					{/* Article Details */}
					<section className="py-40">
						{/* Meta Section */}
						<h3 className="text-grey">
							{article.author} / {article.date}
						</h3>

						{/* Title Section */}
						<h2 className="h1 w-10/12 pb-30 lg:pb-60 group-hover:text-blue group-hover:underline">
							{article.title}
						</h2>

						{/* Image Section */}
						<Image asset={article.thumbnail} />
					</section>
					{/* Article Content */}
					<section className="py-40 lg:py-80 blog">
						{parse(article.content)}
					</section>

					{/* Social Section */}
					<section className="py-20 lg:py-40">
						<div className="relative bg-placeholder text-center">
							<div className="w-6/12 py-120 mx-auto">
								<h3>Liked it? Share it</h3>
								<div id="widgets" className="flex justify-center pt-20"></div>
							</div>
						</div>
					</section>
				</div>
			</div>
		</Layout>
	)
}

ArticlePage.propTypes = {
	data: PropTypes.shape({
		strapi: PropTypes.shape({
			article: PropTypes.shape({
				author: PropTypes.string.isRequired,
				date: PropTypes.string.isRequired,
				title: PropTypes.string.isRequired,
				thumbnail: PropTypes.shape({}).isRequired,
				content: PropTypes.string.isRequired,
				metadata: PropTypes.shape({}).isRequired,
			}).isRequired,
		}).isRequired,
	}).isRequired,
}

export const query = graphql`
	query Blogs($id: ID!) {
		strapi {
			article: blog(id: $id) {
				slug
				author
				date
				title
				thumbnail {
					url
					ext
					height
					width
					name
					imageFile {
						publicURL
						childImageSharp {
							gatsbyImageData(
								width: 1075
								transformOptions: { cropFocus: CENTER, fit: COVER }
								layout: CONSTRAINED
								formats: [AUTO, WEBP, AVIF]
							)
						}
					}
				}
				content
				metadata {
					meta_title
					meta_desc
					meta_image {
						url
						imageFile {
							publicURL
						}
					}
				}
			}
			blog: blogPage {
				footer_headline
				footer_link_url
				footer_link_text
			}
		}
	}
`

export default ArticlePage
